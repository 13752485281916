<template>
  <content-wrapper-block
    v-observe-visibility="{
      callback: handleVisibilityChange,
      intersection: {
        threshold: 0.1,
      },
    }"
    class="header-content"
  >
    <div class="header-content__flats-count">
      <flats-counter />
    </div>
    <div class="header-content__block title-block">
      <div class="title-block__left">
        <h1 v-if="title" class="header-content__title" v-html="title" />
        <h2
          v-if="subtitle"
          class="header-content__subtitle"
          v-html="subtitle"
        />
      </div>
      <div class="title-block__rigth">
        <div class="title-block__addition" v-html="addition" />
      </div>
    </div>
    <div class="header-content__block time-block">
      <time-block :items="items">
        <div slot="block" class="time-block__flex">
          <countdown-timer
            :final-date="finalDate"
            class="time-block__flex-countdown"
            title="ДНЕЙ ДО ПОВЫШЕНИЯ ЦЕНЫ"
          />
        </div>
      </time-block>
      <div v-if="$slots.navigation" class="header-content__nav">
        <slot name="navigation" />
      </div>
    </div>
    <div class="header-content__block content-list">
      <div style="width: 100%; overflow: auto; pointer-events: all;">
        <!-- <stories-block /> -->
      </div>
      <div class="content-list__wide">
        <div
          v-if="$slots.navigation"
          class="header-content__nav header-content__nav--mob"
        >
          <slot name="navigation" />
        </div>
      </div>
    </div>
  </content-wrapper-block>
</template>

<script>
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import TimeBlock from "@/components/elements/TimeBlock";
import ContentItem from "@/components/elements/ContentItem";
import CountdownTimer from "@/components/elements/CountdownTimer";
import UiButton from "@/components/ui/UIButton";
import { mapMutations } from "vuex";
// import StoriesBlock from "./StoriesBlock.vue";
import FlatsCounter from "@/components/elements/FlatsCounter";

export default {
  name: "HeaderBlock",
  components: {
    // StoriesBlock,
    ContentWrapperBlock,
    CountdownTimer,
    ContentItem,
    TimeBlock,
    UiButton,
    FlatsCounter,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
      default: "",
    },
    addition: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      items: [
        {
          big: "5",
          normal: "минут",
          text: "До метро",
        },
        {
          big: "15",
          normal: "минут",
          text: "От центра",
        },
      ],
      finalDate: new Date("2022-09-25"),
    };
  },
  mounted() {
    this.getSlideSlug();
    this.getContent();
  },
  methods: {
    ...mapMutations({
      setFixed: "SET_FIXED",
    }),
    getSlideSlug() {
      const urLSearchParams = new URLSearchParams(location.search);
      const slide = urLSearchParams.get("slide");

      if (slide) {
        this.$cookies.set("slide-content", slide, 60 * 60 * 24 * 7);
        return slide.toString();
      } else if (this.$cookies.get("slide")) {
        return this.$cookies.get("slide").toString();
      } else {
        return "default";
      }
    },
    getContent() {
      // if (this.getSlideSlug() === '1') {
      //   this.title = 'Скидки<br>до&nbsp;1&nbsp;250&nbsp;000&nbsp;Р'
      //   this.subtitle = 'Акция действует до 15 июля на ограниченный объем апартаментов'
      // }
      // if (this.getSlideSlug() === '2') {
      //   this.title = 'ЛИЦА ТВОЕЙ<br>УЛИЦЫ'
      //   this.subtitle = 'Ипотека от<br> 3 500р/мес<br> на апартаменты'
      // }
    },
    handleVisibilityChange(visible) {
      this.setFixed(!visible);
    },
  },
};
</script>

<style lang="scss" scoped>
.link-subtitle {
  color: #ffffff;
}
.test {
  display: none;
}
.header-content {
  padding-top: 45px;
  // padding-top: 25px;
  padding-bottom: 0px;

  // @media screen and (min-width: 360px) {
  //   // padding-top: 37px;
  //   // padding-bottom: 0;
  // }
  // @media screen and (min-width: $screen-xs) {
  //   padding-top: 45px;
  //   padding-bottom: 20px;
  // }
  @media screen and (min-width: $screen-sm) {
    padding: 45px 15px;
  }

  &__flats-count {
    position: absolute;
    top: 94px;
    right: 0;

    @media screen and (max-width: $screen-md) {
      top: 10px;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 400;
    line-height: 98%;
    text-transform: uppercase;
    color: #ffffff;
    margin: 0 0 10px;

    @media screen and (min-width: 360px) {
      font-size: 47px;
    }
    @media screen and (min-width: $screen-xs) {
      font-size: 53px;
    }
    @media screen and (min-width: $screen-mini) {
      font-size: 96px;
    }
  }

  &__link {
    text-decoration: none;
  }

  &__subtitle {
    display: inline;
    width: fit-content;
    cursor: pointer;
    position: relative;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 132.5%;
    letter-spacing: 0.03em;
    color: #ffffff;
    opacity: 0.78;
    background: none;
    margin: 0;
    pointer-events: auto;
    border-bottom: 1px solid #fff;
    ::v-deep {
      a {
        color: inherit;
        text-decoration: none;
      }
    }

    @media screen and (min-width: 375px) {
      font-size: 14px;
    }
    @media screen and (min-width: $screen-xs) {
      font-size: 16px;
    }
    @media screen and (min-width: $screen-mini) {
      font-size: 23px;
    }

    &:hover {
      border-color: transparent;
    }

    ::v-deep {
      b,
      strong {
        color: #e14313;
        font-weight: 400;
      }
    }
  }

  &__nav {
    pointer-events: auto;

    @media screen and (max-width: $screen-mini - 1) {
      display: none;
    }

    @media screen and (min-width: $screen-mini) and (max-width: $screen-md - 1) {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    &--mob {
      display: block;

      @media screen and (min-width: $screen-mini) {
        display: none;
      }
    }
  }
  &__video {
    position: relative;
    z-index: 1;
    @media (max-width: $screen-md - 1) {
      position: absolute;
      right: 0;
      bottom: 30%;
    }
    @media screen and (max-width: $screen-mini) {
      width: 100%;
      position: relative;
      bottom: 0;
    }
    &-button {
      position: absolute;
      border: none;
      background: transparent;
      outline: none;
      right: -9px;
      padding: 0;
      z-index: 1;
      cursor: pointer;
      bottom: 0;
      @media (max-width: $screen-md - 1) {
        right: 0;
        bottom: 0;
      }
      @media screen and (max-width: $screen-mini) {
        width: 85px;
        margin-left: auto;
        top: 0;
        right: 0;
        img {
          width: 100%;
        }
      }
    }
  }
  .title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__addition {
      font-style: normal;
      font-weight: 400;
      font-size: 280px;
      line-height: 98%;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #FFFFFF;
      text-align: right;
      @media screen and (max-width: 768px) {
        font-size: 120px;
        line-height: 98%;
      }
    }
    @media screen and (max-width: 1199px) {
      flex-direction: column-reverse;
      align-items: unset;
    }
    @media screen and (max-width: $screen-xs) {
      padding-top: 0;
    }
  }
}

.content-list {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;

  @media screen and (min-width: $screen-mini) {
    flex-direction: row;
    align-items: center;
  }

  @media screen and (min-width: $screen-md) {
    justify-content: space-between;
  }

  &__item {
    &--orange {
      ::v-deep {
        .content-item__text {
          background-color: #fff;
          color: #e14313;
        }
      }
    }
  }

  &__wide {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $screen-mini - 1) {
      width: 100%;
    }
  }
}

.time-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin: 50px 0 0;

  @media screen and (min-width: $screen-md) {
    flex-direction: row;
    align-items: flex-end;
  }

  &__apart-btn {
    @media screen and (max-width: 374px) {
      padding: 11px 20px;
    }
  }

  &__trade-in {
    text-decoration: none;
    cursor: pointer;
    @media screen and (max-width: $screen-sm - 1px) {
      display: none;
    }
  }

  ::v-deep {
    .time-block {
      &__container {
        @media screen and (max-width: $screen-md - 1) {
          display: block;
        }

        @media screen and (min-width: $screen-md) {
          align-items: flex-end;
        }
      }

      &__content {
        width: fit-content;

        @media screen and (max-width: $screen-lg - 1) {
          margin: 0 30px 0 0;
        }

        @media screen and (min-width: $screen-lg) {
          margin: 0;
        }
      }

      &__text {
        @media screen and (min-width: $screen-lg) {
          left: 345px;
        }
      }
    }
  }

  @media screen and (min-width: $screen-md) {
    margin: 14.5vh 0 2vh 0;
  }

  @media screen and (min-width: $screen-lg) {
    margin: 16.5vh 0 1vh 0;
  }

  &__promo-btn {
    border: 2px solid #e14313;
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding: 7px 24px;
    box-sizing: border-box;
    margin-top: 16px;
    pointer-events: auto;
    text-decoration: none;
    transition: 300ms background-color;

    @media screen and (min-width: $screen-sm) {
      margin-top: 0;
      margin-left: 36px;
    }

    &:hover {
      background: #e14313;
    }
  }

  &__promo-btn-icon {
    margin-right: 12px;
  }

  &__promo-btn-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 98%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
  }

  &__flex {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    @media screen and (min-width: $screen-md) {
      flex-direction: row;
      align-items: flex-end;
      margin-top: 0;
    }

    &-countdown {
      margin-bottom: 32px;

      @media screen and (min-width: $screen-md) {
        margin: 0 29px 0 0;
      }
    }

    &-box {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      @media screen and (min-width: $screen-sm) {
        flex-direction: row;
        align-items: flex-end;
      }
    }

    &-btns {
      display: flex;
      align-items: flex-end;
      pointer-events: auto;
      width: 100%;
    }

    &-phone {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      border: 1px solid #ffffff;
      margin-left: 14px;

      @media screen and (min-width: $screen-mini) {
        display: none;
      }
    }

    ::v-deep {
      .ui-button {
        @media screen and (min-width: $screen-mini) and (max-width: $screen-md) {
          padding: 10px 25px;
          font-size: 10px;
        }
      }
    }
  }
}
</style>
