var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "content-wrapper-block",
    {
      directives: [
        {
          name: "observe-visibility",
          rawName: "v-observe-visibility",
          value: {
            callback: _vm.handleVisibilityChange,
            intersection: {
              threshold: 0.1,
            },
          },
          expression:
            "{\n    callback: handleVisibilityChange,\n    intersection: {\n      threshold: 0.1,\n    },\n  }",
        },
      ],
      staticClass: "header-content",
    },
    [
      _c(
        "div",
        { staticClass: "header-content__flats-count" },
        [_c("flats-counter")],
        1
      ),
      _c("div", { staticClass: "header-content__block title-block" }, [
        _c("div", { staticClass: "title-block__left" }, [
          _vm.title
            ? _c("h1", {
                staticClass: "header-content__title",
                domProps: { innerHTML: _vm._s(_vm.title) },
              })
            : _vm._e(),
          _vm.subtitle
            ? _c("h2", {
                staticClass: "header-content__subtitle",
                domProps: { innerHTML: _vm._s(_vm.subtitle) },
              })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "title-block__rigth" }, [
          _c("div", {
            staticClass: "title-block__addition",
            domProps: { innerHTML: _vm._s(_vm.addition) },
          }),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "header-content__block time-block" },
        [
          _c("time-block", { attrs: { items: _vm.items } }, [
            _c(
              "div",
              {
                staticClass: "time-block__flex",
                attrs: { slot: "block" },
                slot: "block",
              },
              [
                _c("countdown-timer", {
                  staticClass: "time-block__flex-countdown",
                  attrs: {
                    "final-date": _vm.finalDate,
                    title: "ДНЕЙ ДО ПОВЫШЕНИЯ ЦЕНЫ",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.$slots.navigation
            ? _c(
                "div",
                { staticClass: "header-content__nav" },
                [_vm._t("navigation")],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "header-content__block content-list" }, [
        _c("div", {
          staticStyle: {
            width: "100%",
            overflow: "auto",
            "pointer-events": "all",
          },
        }),
        _c("div", { staticClass: "content-list__wide" }, [
          _vm.$slots.navigation
            ? _c(
                "div",
                { staticClass: "header-content__nav header-content__nav--mob" },
                [_vm._t("navigation")],
                2
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }