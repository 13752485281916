<template>
  <div class="parking-info">
    <div class="parking-info__content">
      <div class="parking-info__item">
        <div class="parking-info__label">
          {{ mobileTitle("Этаж") }}
        </div>
        <div class="parking-info__text">
          {{ floor }}
        </div>
      </div>
      <div class="parking-info__item">
        <div class="parking-info__label">
          {{ mobileTitle("Номер", "№") }}
        </div>
        <div class="parking-info__text">
          {{ number }}
        </div>
      </div>
      <div class="parking-info__item">
        <div class="parking-info__label">
          {{ mobileTitle("Площадь, м²", "S, м²") }}
        </div>
        <div class="parking-info__text">
          {{ area }}
        </div>
      </div>
      <div class="parking-info__item parking-price">
        <div class="parking-info__label">
          {{ mobileTitle("Цена, ₽", "Цена млн, ₽") }}
        </div>
        <div class="parking-info__text">
          <template v-if="newPrice">
            <span class="parking-info__new-price">
              {{ currency(newPrice) }} ₽
            </span>
            <s class="parking-info__full-price">{{ currency(price) }} ₽</s>
          </template>
          <template v-else>
            {{ currency(price) }} ₽
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ParkingInfo",
  props: ["price", "floor", "number", "area", 'newPrice'],
  data: () => ({
    isMobile: window.innerWidth < 768 ? "true" : "false",
  }),
  created() {
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 768 ? "true" : "false";
    });
  },
  methods: {
    mobileTitle: function (desktop, mobile = "") {
      return this.isMobile === "true"
        ? mobile === ""
          ? desktop
          : mobile
        : desktop;
    },
    currency(price) {
      if (this.isMobile === 'true') return ((price / 1000000).toFixed(1)).toLocaleString('ru')
      return price.toLocaleString('ru')
    }
  },
};
</script>

<style lang="scss" scoped>
.parking-info {
  background: rgba(255, 255, 255, 0.1);
  padding: 16px 18px;

  @media screen and (min-width: $screen-sm) {
    padding: 26px 40px;
  }

  &__content {
    display: flex;
    align-items: center;
  }
  &__item {
    display: flex;
    flex-direction: column;
    margin-right: 16px;

    @media screen and (min-width: $screen-sm) {
      margin-right: 35px;
    }
  }
  &__label {
    font-family: "Bebas Neue", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 132.5%;
    letter-spacing: 0.18em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 10px;
    white-space: nowrap;
  }
  &__text {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: "Bebas Neue", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 98%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
  }

  &__new-price {
    color: #e14313;
  }

  &__full-price {
    font-size: 14px;
    color: #ffffff99;
  }
}
.parking-price {
  padding-left: 16px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);

  @media screen and (min-width: $screen-sm) {
    padding-left: 35px;
  }
}
</style>
