<template>
  <div class="flats-counter">
    <div class="flats-counter__wrapper">
      <div class="flats-counter__title">
        <span class="flats-counter__count">{{ normalizeCount(count) }}</span>
        {{ normalizeTitle(count, title) }}
      </div>
      <div class="flats-counter__subtitle">
        {{ normalizeTitle(count, subtitle) }} в продаже
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FlatsCounter',
  data() {
    return {
      title: ['лот', 'лота', 'лотов'],
      subtitle: ['остался', 'осталось', 'осталось'],
    }
  },
  methods: {
    normalizeTitle(num, dec) {
      if (num > 100) num = num % 100;
      if (num <= 20 && num >= 10) return dec[2];
      if (num > 20) num = num % 10;
      return num === 1 ? dec[0] : num > 1 && num < 5 ? dec[1] : dec[2];
    },
    normalizeCount(num) {
      return num < 10 ? `0${num}` : num
    },
  },
  computed: {
    count() {
      return this.$store.getters.apartments.length
    }
  }
}
</script>

<style lang="scss" scoped>
.flats-counter {
    letter-spacing: 0.05em;
    font-weight: 350;
    padding-top: 37px;

  &__wrapper {
    padding: 0px 36px 8px 24px;
    background-color: #1E1919;
  }

  &__title {
    font-size: 20px;
    color: #FFFFFF
  }

  &__count {
    font-size: 80px;
    font-weight: 700;
    line-height: 30px;
    color: #E14313;
  }

  &__subtitle {
    padding-top: 5px;
    font-size: 14px;
    color: #FFFFFFCC;
  }
}

</style>
