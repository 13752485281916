var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "parking-info" }, [
    _c("div", { staticClass: "parking-info__content" }, [
      _c("div", { staticClass: "parking-info__item" }, [
        _c("div", { staticClass: "parking-info__label" }, [
          _vm._v(" " + _vm._s(_vm.mobileTitle("Этаж")) + " "),
        ]),
        _c("div", { staticClass: "parking-info__text" }, [
          _vm._v(" " + _vm._s(_vm.floor) + " "),
        ]),
      ]),
      _c("div", { staticClass: "parking-info__item" }, [
        _c("div", { staticClass: "parking-info__label" }, [
          _vm._v(" " + _vm._s(_vm.mobileTitle("Номер", "№")) + " "),
        ]),
        _c("div", { staticClass: "parking-info__text" }, [
          _vm._v(" " + _vm._s(_vm.number) + " "),
        ]),
      ]),
      _c("div", { staticClass: "parking-info__item" }, [
        _c("div", { staticClass: "parking-info__label" }, [
          _vm._v(" " + _vm._s(_vm.mobileTitle("Площадь, м²", "S, м²")) + " "),
        ]),
        _c("div", { staticClass: "parking-info__text" }, [
          _vm._v(" " + _vm._s(_vm.area) + " "),
        ]),
      ]),
      _c("div", { staticClass: "parking-info__item parking-price" }, [
        _c("div", { staticClass: "parking-info__label" }, [
          _vm._v(" " + _vm._s(_vm.mobileTitle("Цена, ₽", "Цена млн, ₽")) + " "),
        ]),
        _c(
          "div",
          { staticClass: "parking-info__text" },
          [
            _vm.newPrice
              ? [
                  _c("span", { staticClass: "parking-info__new-price" }, [
                    _vm._v(" " + _vm._s(_vm.currency(_vm.newPrice)) + " ₽ "),
                  ]),
                  _c("s", { staticClass: "parking-info__full-price" }, [
                    _vm._v(_vm._s(_vm.currency(_vm.price)) + " ₽"),
                  ]),
                ]
              : [_vm._v(" " + _vm._s(_vm.currency(_vm.price)) + " ₽ ")],
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }