var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flats-counter" }, [
    _c("div", { staticClass: "flats-counter__wrapper" }, [
      _c("div", { staticClass: "flats-counter__title" }, [
        _c("span", { staticClass: "flats-counter__count" }, [
          _vm._v(_vm._s(_vm.normalizeCount(_vm.count))),
        ]),
        _vm._v(" " + _vm._s(_vm.normalizeTitle(_vm.count, _vm.title)) + " "),
      ]),
      _c("div", { staticClass: "flats-counter__subtitle" }, [
        _vm._v(
          " " +
            _vm._s(_vm.normalizeTitle(_vm.count, _vm.subtitle)) +
            " в продаже "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }