<template>
  <div class="header" mascot @touchstart="$root.showMascot($event)">
    <info-line v-if="lineVisible" class="header__line" :list="line.list" />
    <div class="header__box">
      <header-block :fixed="fixed" :phone="phone" />
      <swiper
        v-if="bannerVisible"
        ref="swiper"
        class="header__swiper"
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="(slide, i) in slides"
          :key="i"
          class="header__slide"
        >
          <video
            v-if="i === 0"
            :key="updateKey"
            ref="video"
            class="header__video"
            :poster="require('@/assets/images/offer/video-poster.jpg')"
            autoplay
            loop
            muted
            playsinline
          >
            <source
              type="video/mp4"
              :src="require('@/assets/videos/offer/01-hd.mp4')"
              media="(min-width: 768px)"
            />
            <source
              type="video/mp4"
              :src="require('@/assets/videos/offer/01.mp4')"
              media="(min-width: 0px)"
            />
          </video>
          <picture v-else>
            <source
              v-for="(source, j) in slide.picture"
              :key="j"
              :type="source.type"
              :data-srcset="source.srcset"
              :media="source.media"
            />
            <img
              class="header__img swiper-lazy"
              :data-src="slide.picture[0].src"
              alt=""
            />
          </picture>
        </swiper-slide>
      </swiper>
      <div class="header__content">
        <header-content
          v-if="bannerVisible && firstSlide"
          :title="firstSlide.title"
          :subtitle="firstSlide.subtitle"
          :addition="firstSlide.addition_text_2"
          :phone="phone"
        >
          <slider-navigation-arrows
            v-if="swiperActive"
            slot="navigation"
            class="header__nav"
            @next="slideNext"
            @prev="slidePrev"
          />
        </header-content>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import HeaderBlock from "@/components/blocks/header/HeaderBlock";
import HeaderContent from "@/components/blocks/HeaderBlock";
import InfoLine from "@/components/blocks/InfoLine";
import SliderNavigationArrows from "@/components/ui/SliderNavigationArrows";

export default {
  name: "TheHeader",
  components: {
    HeaderBlock,
    HeaderContent,
    InfoLine,
    SliderNavigationArrows,
  },
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
    bannerVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phone: "+7 (495) 432-40-97",
      swiperOptions: null,
      initialSlide: 0,
      updateKey: false,
    };
  },
  computed: {
    ...mapState({
      storage: (state) => state.storage,
    }),
    line() {
      return this.storage?.info_line;
    },
    lineVisible() {
      return this.$route.path === "/" && this.line?.is_active;
    },
    slides() {
      return this.storage?.main_offer_slider?.slides;
    },
    swiperActive() {
      return this.slides?.length > 1;
    },
    firstSlide() {
      return this.slides?.length > 0 ? this.slides[0] : null;
    },
  },
  created() {
    this.initSlideByQuery();
    this.initSwiperOptions();
  },
  activated() {
    this.updateKey = true; // video not playing after route update due to keep-alive
    this.$nextTick(() => {
      const videoEls = this.$refs.video;

      if (videoEls) {
        videoEls[0].play?.();
      }
    });
  },
  deactivated() {
    this.updateKey = false;
  },
  methods: {
    initSlideByQuery() {
      if (!this.swiperActive) {
        return;
      }

      const COOKIE_KEY = "main/offer/slide";
      const querySlide = this.$route.query?.slide;

      if (querySlide && this.slides[+querySlide]) {
        this.$cookies.set(COOKIE_KEY, querySlide, {
          path: "/",
          maxAge: 60 * 60 * 24 * 7,
        });
        return (this.initialSlide = +querySlide);
      }

      const cookieSlide = this.$cookies.get(COOKIE_KEY);

      if (cookieSlide) {
        this.initialSlide = +cookieSlide;
      }
    },
    initSwiperOptions() {
      if (!this.swiperActive) {
        return (this.swiperOptions = {
          allowSlideNext: false,
          allowSlidePrev: false,
          allowTouchMove: false,
        });
      }
      this.swiperOptions = {
        initialSlide: this.initialSlide,
        loop: true,
        navigation: {
          nextEl: ".header__arrow--next",
          prevEl: ".header__arrow--prev",
        },
        autoplay: !this.initialSlide,
        preloadImages: false,
        lazy: {
          loadPrevNext: true,
        },
      };
    },
    getSwiper() {
      return this.$refs.swiper.$swiper;
    },
    handleLineClick(e, { href, additional_field }) {
      if (href === "#modal") {
        e.preventDefault();
        this.showSubscribeDialog(
          additional_field,
          "",
          "Отправить",
          "info-line-callback"
        );
      }
    },
    slideNext() {
      this.getSwiper()?.slideNext();
    },
    slidePrev() {
      this.getSwiper()?.slidePrev();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;

  &__line {
    z-index: 2;
  }

  &__box {
    position: relative;
    background-color: #111;
    user-select: none;
  }

  &__swiper {
    &,
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    backface-visibility: hidden;

    @media (min-width: $screen-md) and (max-width: $screen-lg) {
      top: 71px;
    }

    &::after {
      z-index: 1;
      content: "";
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.9)
      );
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  &__content {
    z-index: 2;
    position: relative;
    // padding-top: 0;
    padding-top: 71px;
    pointer-events: none;

    // @media (min-width: $screen-xs) {
    //   padding-top: 71px;
    // }
  }

  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__nav {
    ::v-deep {
      align-items: flex-end;
      border: none;
      padding: 0;
    }
  }
}
</style>
