<template>
  <footer ref="footer" class="footer-block">
    <div class="footer-block__wrapper">
      <bread-crumbs v-if="showBreadCrumbs" main-link-name="Mainstreet" light />
      <div class="footer-block__menus">
        <div class="footer-block-menu">
          <div class="footer-block-menu__title" @click="show('first')">
            MainStreet
            <i
              :class="{
                'footer-block-menu__title-arrow': true,
                'footer-block-menu__title-arrow--opened': tabs.first,
              }"
            >
              <arrow-down-icon color="#FFFFFF" width="9px" height="9px" />
            </i>
          </div>
          <div
            :class="{
              'footer-block-menu__body': true,
              'footer-block-menu__body--opened': tabs.first,
            }"
          >
            <router-link
              :to="{ name: 'AboutPage' }"
              class="footer-block-menu__item"
            >
              О проекте
            </router-link>
            <router-link
              :to="{ name: 'LocationPage' }"
              class="footer-block-menu__item"
            >
              Расположение
            </router-link>
            <router-link
              :to="{ name: 'PanoramaPage' }"
              class="footer-block-menu__item"
            >
              Панорама
            </router-link>
            <router-link
              :to="{ name: 'ParkingPage' }"
              class="footer-block-menu__item"
            >
              Паркинг
            </router-link>
            <router-link
              :to="{ name: 'ContactPage' }"
              class="footer-block-menu__item"
            >
              Контакты
            </router-link>
          </div>
        </div>
        <div class="footer-block-menu">
          <div class="footer-block-menu__title" @click="show('second')">
            Покупателям
            <i
              :class="{
                'footer-block-menu__title-arrow': true,
                'footer-block-menu__title-arrow--opened': tabs.second,
              }"
            >
              <arrow-down-icon color="#FFFFFF" width="9px" height="9px" />
            </i>
          </div>
          <div
            :class="{
              'footer-block-menu__body': true,
              'footer-block-menu__body--opened': tabs.second,
            }"
          >
            <router-link
              :to="{ name: 'NewsCatalogPage' }"
              class="footer-block-menu__item"
            >
              Новости
            </router-link>
            <router-link
              :to="{ name: 'ConditionPage' }"
              class="footer-block-menu__item"
            >
              Способы покупки
            </router-link>
            <div
              class="footer-block-menu__item"
              data-popup-with-chats
              data-popup-with-chats-title="Trade In"
            >
              Trade In
            </div>
            <router-link
              :to="{ name: 'DocumentsPage' }"
              class="footer-block-menu__item"
            >
              Документы
            </router-link>
            <span
              class="footer-block-menu__item"
              data-popup-with-chats
              data-popup-with-chats-source-id="Получить буклет"
              data-popup-with-chats-title="Получить буклет"
            >
              Получить буклет
            </span>
          </div>
        </div>
        <div class="footer-block-menu">
          <div class="footer-block-menu__title" @click="show('third')">
            Апартаменты
            <i
              :class="{
                'footer-block-menu__title-arrow': true,
                'footer-block-menu__title-arrow--opened': tabs.third,
              }"
            >
              <arrow-down-icon color="#FFFFFF" width="9px" height="9px" />
            </i>
          </div>
          <div
            :class="{
              'footer-block-menu__body': true,
              'footer-block-menu__body--opened': tabs.third,
            }"
          >
            <router-link
              :to="{ name: 'FilterPage' }"
              class="footer-block-menu__item"
            >
              Выбор апартаментов
            </router-link>
            <!-- <router-link
              :to="{ name: 'ApartmentDecorationPage' }"
              class="footer-block-menu__item"
            >
              Отделка
            </router-link> -->
          </div>
        </div>
        <div class="footer-block-menu footer-block-menu__logo">
          <icon-osnova-logo />
          <div class="footer-block-menu__socials social">
            <a
              class="social__link"
              href="https://t.me/kvartal_mainstreet"
              target="__blank"
            >
              <icon-telegram-footer />
            </a>
            <a
              class="social__link"
              href="https://vk.com/osnova_mainstreet"
              target="__blank"
            >
              <icon-vk-footer />
            </a>
          </div>
        </div>
      </div>
      <div class="footer-block__license">
        <div class="footer-block__agent">
          <span
            class="footer-block__agent-link"
            role="button"
            data-popup-with-chats
            data-popup-with-chats-title="Агентствам недвижимости по вопросам сотрудничества"
            data-popup-with-chats-source-id="Агентства недвижимости"
          >
            Агентствам недвижимости по вопросам сотрудничества
          </span>
        </div>
        Настоящий сайт носит исключительно информационный характер. Продолжая
        просмотр страниц сайта вы соглашаетесь со всеми условиями
        <router-link :to="{ name: 'UserAgreementPage' }">
          Пользовательского соглашения </router-link>и
        <a href="/Политика_обработки_персональных_данных.pdf" target="_blank">
          Политикой обработки персональных данных</a>
        . © {{ year }}
        <a
          href="https://gk-osnova.ru/?utm_source=zkmainstreet.ru"
          target="_blank"
          >АО "ГК "ОСНОВА"</a
        >. Проектная документация на <a href="/documents">сайте</a> застройщика.
      </div>
    </div>
    <!-- Костыль для прохождения проверки Гугла. Пишем цветом фона футера. -->
    <p style="color: #383838; font-size: 5px">
      Номер телефона для визитки Google 7 (495) 432-41-97
    </p>
  </footer>
</template>

<script>
import { mapState } from "vuex";
import ArrowDownIcon from "@/components/icons/ArrowDownIcon";
import IconOsnovaLogo from "../icons/IconOsnovaLogo.vue";
import IconTelegramFooter from "../icons/IconTelegramFooter.vue";
import IconVkFooter from "@/components/icons/IconVkFooter";
import BreadCrumbs from "@/components/elements/BreadCrumbs";

export default {
  name: "FooterBlock",
  components: {
    IconOsnovaLogo,
    ArrowDownIcon,
    IconTelegramFooter,
    IconVkFooter,
    BreadCrumbs,
  },
  data() {
    return {
      tabs: {
        first: false,
        second: false,
        third: false,
      },
    };
  },
  mounted() {
    this.$root.setMascotObserver(this.$refs.footer);
  },
  methods: {
    show(tab) {
      this.tabs[tab] = !this.tabs[tab];
    },
  },
  computed: {
    ...mapState({
      storage: (state) => state.storage,
    }),
    year() {
      return new Date().getUTCFullYear();
    },
    showBreadCrumbs() {
      const { name } = this.$route;
      return name === "OnlineShopping";
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-block {
  background: #383838;
  color: #ffffff;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  a {
    color: #ffffff;
  }

  &__wrapper {
    width: 100%;
    max-width: 1160px;
    padding: 50px;
    margin: 0 auto;
    box-sizing: border-box;

    @media screen and (max-width: $screen-md) {
      padding: 25px 15px;
    }
  }

  &-recall {
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__phone {
      font-size: 25px;
      font-weight: 300;
      text-decoration: none;

      @media only screen and (max-width: $screen-sm) {
        font-size: 14px;
      }
    }
  }

  &__license {
    font-size: 11px;

    a {
      text-decoration: underline;
    }
  }

  &__menus {
    display: flex;
    margin-top: 25px;
    justify-content: space-between;

    @media only screen and (max-width: $screen-sm) {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  &-menu {
    margin-bottom: 55px;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $screen-sm) {
      margin-bottom: 0;
    }

    &__logo {
      @media only screen and (max-width: $screen-sm) {
        margin-top: 50px;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__socials,
    .social {
      margin-top: 5px;
      @media only screen and (max-width: $screen-sm) {
        text-align: center;
      }
      &__link {
        margin-left: 10px;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    &__title {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 20px;
      text-transform: uppercase;

      &-arrow {
        display: none;
        transition: all 300ms ease-out;

        @media only screen and (max-width: $screen-sm) {
          display: inline-block;

          &--opened {
            transform: rotate(180deg);
          }
        }
      }

      @media only screen and (max-width: $screen-sm) {
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      @media only screen and (max-width: $screen-sm) {
        max-height: 0;
        transition: all 300ms ease-out;

        &--opened {
          max-height: 500px;
          margin-bottom: 50px;
        }
      }
    }

    &__item {
      font-size: 12px;
      margin-bottom: 10px;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: #adadad;
      }
    }
  }
  &__agent {
    margin-bottom: 20px;
    &-link {
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>
