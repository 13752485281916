import Vue from "vue";
import router from "./router";
import axios from "axios";
import App from "./App.vue";
// import './registerServiceWorker'
import store from "./store";
import moment from "moment";
// import "@/plugins";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import YmapPlugin from "vue-yandex-maps";
import VueObserveVisibility from "vue-observe-visibility";
import VueMask from "v-mask";
import VueGtm from "@gtm-support/vue2-gtm";
import Meta from "vue-meta";
import * as VeeValidate from "vee-validate";
import Vuelidate from "vuelidate";
import VuePano from "@black40x/vue-pano";
import Multiselect from "vue-multiselect";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import VueCookies from "vue-cookies";
import { mapState, mapMutations } from "vuex";
import prototypes from "./utils/prototypes";
import VueYandexMetrika from "vue-yandex-metrika";
import { PopupWithChats, ButtonWithChats } from "@osnova-adv/popup-with-chats"

Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.use(VueAwesomeSwiper);
Vue.use(YmapPlugin);
Vue.use(VueObserveVisibility);
Vue.use(VueMask);
Vue.use(Meta);
Vue.use(VeeValidate);
Vue.use(Vuelidate);
Vue.use(VuePano);

Vue.component("VueSlider", VueSlider);
Vue.component("Multiselect", Multiselect);

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,
  defer: true,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  ignoredViews: [],
  trackOnNextTick: false,
});

Vue.prototype.$api = axios.create({
  baseURL: process.env.VUE_APP_API_ADMIN,
});
Vue.prototype.$ipoteka = (price, pay, percent, years, coefficient=1) => {
  const i = parseFloat(percent / 100 / 12);
  const n = parseFloat(years * 12);
  const r =
    (price - pay) * coefficient * ((i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1));
  return r.toFixed(2);
};
if (process.env.VUE_APP_CI_COMMIT) {
  window.VUE_APP_CI_COMMIT = process.env.VUE_APP_CI_COMMIT;
}
Vue.prototype.$openPopupWithChat = function (title = '', idModal = '') {
  const el = document.createElement('div')
  el.setAttribute('data-popup-with-chats', '')
  el.dataset['popupWithChatsTitle'] = title
  el.dataset['popupWithChatsSourceId'] = idModal
  document.body.appendChild(el)
  el.click()
  el.remove()
}

Object.assign(Vue.prototype, prototypes);

Vue.mixin({
  computed: {
    ...mapState(["mascotFlag"]),
    adminApi() {
      return (
        process.env.VUE_APP_API_ADMIN_API +
        process.env.VUE_APP_API_ADMIN_VERSION
      );
    },
    apartmentsApi() {
      return process.env.VUE_APP_APARTMENTS_API;
    },
    parkingApi() {
      return process.env.VUE_APP_PARKING_API;
    },
    project_id() {
      return process.env.VUE_APP_PROJECT_ID;
    },
    buildings_id() {
      return process.env.VUE_APP_BUILDINGS_ID;
    },
  },
  methods: {
    ...mapMutations({
      setMascotFlag: "SET_MASCOT_FLAG",
    }),
    showSuccessDialog() {
      this.$root.$emit("modal-success");
    },
    showApartmentDialog(number, floor, quantity, amount, idModal) {
      this.$root.$emit(
        "modal-apartment",
        number,
        floor,
        quantity,
        amount,
        idModal
      );
    },
    showParkingPromoDialog() {
      this.$root.$emit('modal-parking-promo')
    },
    showVideoPromoDialog(banner = null) {
      this.$root.$emit('modal-video-promo', banner)
    },
    showBanner(title = null, description = null, buttonName = null) {
      this.$root.$emit("modal-banner", title, description, buttonName);
    },
    showSubscribeDialog(
      title = null,
      description = null,
      buttonName = null,
      idModal,
      isSmallTitle = false
    ) {
      this.$root.$emit(
        "modal-subscribe",
        title,
        description,
        buttonName,
        idModal,
        isSmallTitle
      );
    },
    showParkingDialog(flat) {
      this.$root.$emit("modal-parking", flat);
    },
    showPresentationDialog(
      title = null,
      buttonName = null,
      shownPresentation = true,
      idModal = null
    ) {
      this.$root.$emit(
        "modal-presentation",
        title,
        buttonName,
        shownPresentation,
        idModal
      );
    },
    showSmartHouseDialog(
      title = null,
      buttonName = null,
      shownPresentation = false,
      idModal = null
    ) {
      this.$root.$emit(
        "modal-smart-house",
        title,
        buttonName,
        shownPresentation,
        idModal
      );
    },
    showVideoReviewDialog() {
      this.$root.$emit("video-review");
    },
    showCountdownDialog(
      data = {}
    ) {
      this.$root.$emit("modal-countdown", data);
    },
    getQuantity(data) {
      const associativeRoom = {
        Студия: "1s",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        "Евро-1": "1e",
        "Евро-2": "2e",
        "Евро-3": "3e",
        "Евро-4": "4e",
        "Евро-5": "5e",
      };
      return associativeRoom[data] ? associativeRoom[data] : data;
    },
    showMascot(event) {
      if (!this.mascotFlag) {
        return;
      }
      if (event.type === "touchstart") {
        return this.setMascot();
      }

      const target = event.relatedTarget;

      if (target && target.closest("[mascot]")) {
        return this.setMascot();
      }
    },
    setMascot() {
      this.$root.$emit("mascot");
      return this.setMascotFlag(false);
    },
    setMascotObserver(ref) {
      return new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && this.mascotFlag) {
            this.setMascot();
          }
        },
        { threshold: 0 }
      ).observe(ref);
    },
    getPlanImage(apartment, returnMobile = false) {
      if (apartment.plan_type) {
        const postfix = returnMobile ? "-m" : "";

        return require("@/assets/images/plans/" +
          apartment.plan_type +
          postfix +
          ".png");
      }
      return "";
    },
    formatTimestamp(unixTimestamp) {
      return moment.unix(unixTimestamp).format("DD.MM.YY");
    },
    formatMonthTextTimestamp(unixTimestamp) {
      moment.locale("ru");
      return moment.unix(unixTimestamp).format("MMMM YYYY");
    },
    formatMonthTimestamp(unixTimestamp) {
      return moment.unix(unixTimestamp).format("MM.YY");
    },
    formatYearTimestamp(unixTimestamp) {
      return moment.unix(unixTimestamp).format("YYYY");
    },
    formatQuantity(quantity) {
      switch (quantity) {
        case "1":
          return "1-комнатная";
        case "2":
          return "2-комнатная";
        case "3":
          return "3-комнатная";
        case "4":
          return "4-комнатная";
        case "5":
          return "5-комнатная";
        case "6":
          return "6-комнатная";
        case "7":
          return "7-комнатная";
        case "8":
          return "8-комнатная";
        case "9":
          return "9-комнатная";
        case "10":
          return "10-комнатная";
        case "Студия":
          return "Студия";
      }
      return quantity;
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    PopupWithChats.make('body', {
      callbackModalId: 'popup-with-chats',
      successModalId: 'success-popup-with-chats',
      agreementLink: 'https://gk-osnova.ru/legal/privacy',
      title: process.env.VUE_APP_POPUP_DEFAULT_TITLE,
      subtitle: process.env.VUE_APP_POPUP_SUBTITLE,
      messagerDescription: process.env.VUE_APP_POPUP_MESSAGER_DESC,
    })
    ButtonWithChats.make('body', { buttonId: 'button-with-chats' });
  }
}).$mount("#app");

Vue.use(VueYandexMetrika, {
  id: 54911026,
  router: router,
  env: "production",
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    trackHash: true,
    ecommerce: "dataLayer",
  },
});
