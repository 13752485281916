var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { ref: "footer", staticClass: "footer-block" }, [
    _c(
      "div",
      { staticClass: "footer-block__wrapper" },
      [
        _vm.showBreadCrumbs
          ? _c("bread-crumbs", {
              attrs: { "main-link-name": "Mainstreet", light: "" },
            })
          : _vm._e(),
        _c("div", { staticClass: "footer-block__menus" }, [
          _c("div", { staticClass: "footer-block-menu" }, [
            _c(
              "div",
              {
                staticClass: "footer-block-menu__title",
                on: {
                  click: function ($event) {
                    return _vm.show("first")
                  },
                },
              },
              [
                _vm._v(" MainStreet "),
                _c(
                  "i",
                  {
                    class: {
                      "footer-block-menu__title-arrow": true,
                      "footer-block-menu__title-arrow--opened": _vm.tabs.first,
                    },
                  },
                  [
                    _c("arrow-down-icon", {
                      attrs: { color: "#FFFFFF", width: "9px", height: "9px" },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                class: {
                  "footer-block-menu__body": true,
                  "footer-block-menu__body--opened": _vm.tabs.first,
                },
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "footer-block-menu__item",
                    attrs: { to: { name: "AboutPage" } },
                  },
                  [_vm._v(" О проекте ")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "footer-block-menu__item",
                    attrs: { to: { name: "LocationPage" } },
                  },
                  [_vm._v(" Расположение ")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "footer-block-menu__item",
                    attrs: { to: { name: "PanoramaPage" } },
                  },
                  [_vm._v(" Панорама ")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "footer-block-menu__item",
                    attrs: { to: { name: "ParkingPage" } },
                  },
                  [_vm._v(" Паркинг ")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "footer-block-menu__item",
                    attrs: { to: { name: "ContactPage" } },
                  },
                  [_vm._v(" Контакты ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "footer-block-menu" }, [
            _c(
              "div",
              {
                staticClass: "footer-block-menu__title",
                on: {
                  click: function ($event) {
                    return _vm.show("second")
                  },
                },
              },
              [
                _vm._v(" Покупателям "),
                _c(
                  "i",
                  {
                    class: {
                      "footer-block-menu__title-arrow": true,
                      "footer-block-menu__title-arrow--opened": _vm.tabs.second,
                    },
                  },
                  [
                    _c("arrow-down-icon", {
                      attrs: { color: "#FFFFFF", width: "9px", height: "9px" },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                class: {
                  "footer-block-menu__body": true,
                  "footer-block-menu__body--opened": _vm.tabs.second,
                },
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "footer-block-menu__item",
                    attrs: { to: { name: "NewsCatalogPage" } },
                  },
                  [_vm._v(" Новости ")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "footer-block-menu__item",
                    attrs: { to: { name: "ConditionPage" } },
                  },
                  [_vm._v(" Способы покупки ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "footer-block-menu__item",
                    attrs: {
                      "data-popup-with-chats": "",
                      "data-popup-with-chats-title": "Trade In",
                    },
                  },
                  [_vm._v(" Trade In ")]
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "footer-block-menu__item",
                    attrs: { to: { name: "DocumentsPage" } },
                  },
                  [_vm._v(" Документы ")]
                ),
                _c(
                  "span",
                  {
                    staticClass: "footer-block-menu__item",
                    attrs: {
                      "data-popup-with-chats": "",
                      "data-popup-with-chats-source-id": "Получить буклет",
                      "data-popup-with-chats-title": "Получить буклет",
                    },
                  },
                  [_vm._v(" Получить буклет ")]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "footer-block-menu" }, [
            _c(
              "div",
              {
                staticClass: "footer-block-menu__title",
                on: {
                  click: function ($event) {
                    return _vm.show("third")
                  },
                },
              },
              [
                _vm._v(" Апартаменты "),
                _c(
                  "i",
                  {
                    class: {
                      "footer-block-menu__title-arrow": true,
                      "footer-block-menu__title-arrow--opened": _vm.tabs.third,
                    },
                  },
                  [
                    _c("arrow-down-icon", {
                      attrs: { color: "#FFFFFF", width: "9px", height: "9px" },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                class: {
                  "footer-block-menu__body": true,
                  "footer-block-menu__body--opened": _vm.tabs.third,
                },
              },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "footer-block-menu__item",
                    attrs: { to: { name: "FilterPage" } },
                  },
                  [_vm._v(" Выбор апартаментов ")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "footer-block-menu footer-block-menu__logo" },
            [
              _c("icon-osnova-logo"),
              _c("div", { staticClass: "footer-block-menu__socials social" }, [
                _c(
                  "a",
                  {
                    staticClass: "social__link",
                    attrs: {
                      href: "https://t.me/kvartal_mainstreet",
                      target: "__blank",
                    },
                  },
                  [_c("icon-telegram-footer")],
                  1
                ),
                _c(
                  "a",
                  {
                    staticClass: "social__link",
                    attrs: {
                      href: "https://vk.com/osnova_mainstreet",
                      target: "__blank",
                    },
                  },
                  [_c("icon-vk-footer")],
                  1
                ),
              ]),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "footer-block__license" },
          [
            _vm._m(0),
            _vm._v(
              " Настоящий сайт носит исключительно информационный характер. Продолжая просмотр страниц сайта вы соглашаетесь со всеми условиями "
            ),
            _c(
              "router-link",
              { attrs: { to: { name: "UserAgreementPage" } } },
              [_vm._v(" Пользовательского соглашения ")]
            ),
            _vm._v("и "),
            _c(
              "a",
              {
                attrs: {
                  href: "/Политика_обработки_персональных_данных.pdf",
                  target: "_blank",
                },
              },
              [_vm._v(" Политикой обработки персональных данных")]
            ),
            _vm._v(" . © " + _vm._s(_vm.year) + " "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://gk-osnova.ru/?utm_source=zkmainstreet.ru",
                  target: "_blank",
                },
              },
              [_vm._v('АО "ГК "ОСНОВА"')]
            ),
            _vm._v(". Проектная документация на "),
            _c("a", { attrs: { href: "/documents" } }, [_vm._v("сайте")]),
            _vm._v(" застройщика. "),
          ],
          1
        ),
      ],
      1
    ),
    _c("p", { staticStyle: { color: "#383838", "font-size": "5px" } }, [
      _vm._v(" Номер телефона для визитки Google 7 (495) 432-41-97 "),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer-block__agent" }, [
      _c(
        "span",
        {
          staticClass: "footer-block__agent-link",
          attrs: {
            role: "button",
            "data-popup-with-chats": "",
            "data-popup-with-chats-title":
              "Агентствам недвижимости по вопросам сотрудничества",
            "data-popup-with-chats-source-id": "Агентства недвижимости",
          },
        },
        [_vm._v(" Агентствам недвижимости по вопросам сотрудничества ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }