<template>
  <div id="app">
    <transition name="fade">
      <keep-alive>
        <router-view />
      </keep-alive>
    </transition>
    <success-modal-dialog ref="modal-success" />
    <apartment-modal-dialog ref="modal-apartment" />
    <banner-modal-dialog ref="modal-banner" />
    <subscribe-modal-dialog ref="modal-subscribe" />
    <parking-modal-dialog ref="modal-parking" />
    <presentation-modal-dialog ref="modal-presentation" />
    <smart-house-modal-dialog ref="modal-smart-house" />
    <popup-mascot v-if="mascotActive" :title="mascot.title" :button="mascot.text_button" :formName="mascot.form_name" ref="mascot" />
    <video-review-dialog ref="video-review" />
    <december-promo-popup v-if="false" ref="modal-december-promo" />
    <parking-promo-modal-dialog ref="modal-parking-promo" />
    <video-promo-dialog ref="modal-video-promo" />
    <countdown-popup ref="modal-countdown" />
  </div>
</template>

<script>
import teletype from "@/teletype";
import { mapMutations, mapState, mapActions } from "vuex";
import SuccessModalDialog from "@/components/dialogs/SuccessModalDialog";
import ApartmentModalDialog from "@/components/dialogs/ApartmentModalDialog";
import SubscribeModalDialog from "@/components/dialogs/SubscribeModalDialog";
import ParkingModalDialog from "@/components/dialogs/ParkingModalDialog";
import PresentationModalDialog from "@/components/dialogs/PresentationModalDialog";
import SmartHouseModalDialog from "@/components/dialogs/SmartHouseModalDialog";
import PopupMascot from "@/components/popups/PopupMascot";
import BannerModalDialog from "@/components/dialogs/BannerModalDialog";
import VideoReviewDialog from "@/components/dialogs/VideoReviewDialog";
import CountdownPopup from "@/components/dialogs/CountdownPopup";
import DecemberPromoPopup from "./components/dialogs/DecemberPromoPopup";
import ParkingPromoModalDialog from "./components/dialogs/ParkingPromoModalDialog";
import VideoPromoDialog from "@/components/dialogs/VideoPromoDialog";

export default {
  components: {
    DecemberPromoPopup,
    ParkingModalDialog,
    SubscribeModalDialog,
    ApartmentModalDialog,
    SuccessModalDialog,
    PresentationModalDialog,
    SmartHouseModalDialog,
    PopupMascot,
    BannerModalDialog,
    VideoReviewDialog,
    CountdownPopup,
    ParkingPromoModalDialog,
    VideoPromoDialog,
  },
  computed: {
    ...mapState({
      menuIsOpened: (state) => state.menuIsOpened,
      storage: (state) => state.storage
    }),
    mascot () {
      if (!this.storage || !this.storage.promo.popup) {
        return null
      }
      let out = null
      this.storage.promo.popup.forEach((item) => {
        if (item.slug === 'mascot') {
          out = item
        }
      })
      return out
    }
  },
  watch: {
    $route(to) {
      this.setBreadcrumb(this.$route.meta.breadcrumb);
      if (to) {
        this.setMenuOpened(false);
        document.body.style = "overflow: visible;";
        this.$refs["modal-success"].close();
        this.$refs["modal-apartment"].close();
        this.$refs["modal-subscribe"].close();
        this.$refs["modal-banner"].close();
        this.$refs["modal-parking"].close();
        this.$refs["modal-presentation"].close();
        this.$refs["modal-smart-house"].close();
        this.$refs["video-review"].close();
        // this.$refs["modal-december-promo"].close()
        this.$refs["modal-parking-promo"].close()
        this.$refs['modal-video-promo'].close()
        this.$refs['modal-countdown'].close()

        if (this.mascotActive) {
          this.$refs.mascot.close();
        }
      }
    },
    mascot () {
      this.setMascotActive()
    },
    menuIsOpened(newVal) {
      if (newVal) {
        document.body.style = "overflow: hidden;";
      } else {
        document.body.style = "";
      }
    },
  },
  data () {
    return {
      mascotActive: false
    }
  },
  created() {
    Promise.all([
      this.fetchDocuments(),
      this.fetchStorage(),
    ])
      .then((response) => {})
      .finally(() => {
        teletype();
      });
    this.fetchApartments();
    this.fetchParking();

    this.$root.$on("modal-success", () => {
      this.$refs["modal-success"].show();
    });
    this.$root.$on(
      "modal-apartment",
      (apartment, floor, quantity, amount, idModal) => {
        this.$refs["modal-apartment"].show(
          apartment,
          floor,
          quantity,
          amount,
          idModal
        );
      }
    );
    this.$root.$on(
      "modal-countdown",
      (data) => {
        this.$refs["modal-countdown"].show(data);
      }
    );
    this.$root.$on(
      "modal-subscribe",
      (title, description, buttonName, idModal, isSmallTitle) => {
        this.$refs["modal-subscribe"].show(
          title,
          description,
          buttonName,
          idModal,
          isSmallTitle
        );
      }
    );
    this.$root.$on(
      "modal-banner",
      (title, description, buttonName, idModal, isSmallTitle) => {
        this.$refs["modal-banner"].show(
          title,
          description,
          buttonName,
          idModal,
          isSmallTitle
        );
      }
    );
    this.$root.$on("modal-parking", (flat) => {
      this.$refs["modal-parking"].show(flat);
    });
    this.$root.$on(
      "modal-presentation",
      (title, description, shownPresentation, idModal) => {
        this.$refs["modal-presentation"].show(
          title,
          description,
          shownPresentation,
          idModal
        );
      }
    );
    this.$root.$on(
      "modal-smart-house",
      (title, description, shownPresentation, idModal) => {
        this.$refs["modal-smart-house"].show(
          title,
          description,
          shownPresentation,
          idModal
        );
      }
    );
    this.$root.$on(
      "video-review",
      () => {
        this.$refs["video-review"].show();
      }
    );
    this.$root.$on(
      "modal-parking-promo",
      () => {
        this.$refs["modal-parking-promo"].show();
      }
    );
    this.$root.$on(
      "modal-video-promo",
      (banner) => {
        this.$refs["modal-video-promo"].show(banner);
      }
    );
    
  },
  methods: {
    ...mapActions({
      fetchApartments: "FETCH_APARTMENTS",
      fetchParking: "FETCH_PARKING",
      fetchDocuments: "FETCH_DOCUMENTS",
      fetchStorage: "FETCH_STORAGE",
    }),
    ...mapMutations({
      setMenuOpened: "SET_MENU_OPENED",
      setNews: "SET_NEWS",
      setBreadcrumb: "SET_BREADCRUMB",
    }),
    setMascotActive () {
      this.mascotActive = this.mascot && this.mascot.activity
      if (this.mascotActive) {
        this.$root.$on("mascot", () => this.$refs.mascot.show());
      }
    }
  },
};
</script>

<style lang="scss">
html {
  --ms-countdown-back: #333333;
  --ms-countdown-card-back: #E0E0E0;
  --ms-countdown-date-color: #E14313;
  --ms-countdown-title--color: #fff;
  --ms-nav-icon-r7m-color: #e14313;
}
html,
body {
  margin: 0;
  padding: 0;
  font-family: "Bebas Neue", Tahoma, serif;
  a {
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }
}

@import "~vue-multiselect/dist/vue-multiselect.min.css";
@import "~@osnova-adv/popup-with-chats/dist/popup-with-chats.css";
@import "~@osnova-adv/ms3-blocks/lib/ms3-blocks.css";

.fade-enter-active,
.fade-leave-active {
  transition: all 0.8s ease-in-out, opacity 0.5s ease-in-out;
}
.fade-enter {
  transform: translateY(-10px);
  opacity: 0;
}

.accent-color {
  color: #e14313;
}

.fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.filter-props__item {
  .multiselect {
    &__tags {
      height: 38px;
      min-height: 38px;
      border: 1px solid #f5f5f5;
      background: #f5f5f5;
      border-radius: 0;
    }

    &__select {
      &:before {
        top: 57% !important;
      }
    }

    &__single {
      font-family: $font-family-rubik;
      font-size: 12px !important;
      color: #929292;
    }

    &__placeholder {
      color: #929292;
      padding-top: 0;
      margin-bottom: 0;
      margin-top: 3px;
    }

    &__content-wrapper {
      border: none;
    }

    &__option {
      color: #2e2a2b !important;
      font-family: Rubik, serif;
      font-size: 12px;

      &:hover {
        color: #e14313 !important;
        background: #f5f5f5 !important;
      }

      &--selected {
        color: #929292 !important;
        font-weight: 400 !important;
      }

      &--highlight {
        color: #e14313 !important;
        background: #f5f5f5 !important;
      }
    }

    &__single {
      font-weight: 300;
      color: #494949;
      background: #f5f5f5;
      font-size: 14px;
    }

    &__content-wrapper {
      border-radius: 0;
    }
  }
}

.subscribe-form__col {
  .multiselect {
    &__tags {
      height: 38px;
      min-height: 38px;
      border: none;
      background: #252525;
      border-radius: 0;
    }

    &__select {
      &:before {
        top: 57% !important;
      }
    }

    &__single {
      font-family: $font-family-rubik;
      font-size: 12px !important;
      color: #929292;
    }

    &__placeholder {
      font-family: $font-family-rubik;
      font-size: 14px;
      font-weight: 400;
      color: #929292;
      padding-top: 0;
      margin-bottom: 0;
      margin-top: 3px;
    }

    &__content-wrapper {
      border: none;
    }

    &__option {
      color: #929292 !important;
      font-family: $font-family-rubik;
      font-size: 12px;
      background: #252525;
      border-bottom: 1px solid #808080;

      &:first-child {
        border-top: 1px solid #808080;
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        color: #fff !important;
        background: #636363 !important;
      }

      &--selected {
        color: #929292 !important;
        font-weight: 400 !important;
      }

      &--highlight {
        color: #929292 !important;
        background: #252525 !important;
      }
    }

    &__single {
      font-weight: 300;
      color: #929292;
      background: #252525;
      font-size: 14px;
    }

    &__content-wrapper {
      border-radius: 0;
    }
  }
}

.vue-slider-process {
  background-color: #ffb8a8 !important;
  border-radius: 15px;
}

.vue-slider-dot-tooltip-inner {
  border-color: #ff4313 !important;
  background-color: #ff4313 !important;
}
//////////////////////////////////////////////
.vue-slider-rail {
  background-color: #cbd0dd !important;
  border-radius: 3px !important;
}

.popup-with-chats {
  background: #F4F4F4;
  border-radius: 0;
}
.popup-with-chats .popup-with-chats__title {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 105%;
  letter-spacing: 0.05em;
  color: #000;
  @media screen and (min-width: 768px) {
    font-size: 30px;
    line-height: 105%;
  }
}

.popup-with-chats .popup-with-chats__subtitle {
  color: #000;
}

.popup-with-chats
  .popup-with-chats__input {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.03em;
    color: #373737;
}
.popup-with-chats
  .popup-with-chats__field-input {
    padding: 12px 0;
    border: 0;
    background: transparent;
    border-bottom: 1px solid #B5B5B5;
    border-radius: 0;
}

.popup-with-chats
  .popup-with-chats__agreement-link {
    border-color: #EC6337;
    color: #000;
  }

.popup-with-chats
  .popup-with-chats__agreement {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    line-height: 132.5%;
    color: rgba(0, 0, 0, 0.52);
  }

.popup-with-chats
  .popup-with-chats__button {
    font-family: 'Bebas Neue';
    background: #E14313;
    border: #E14313;
    border-radius: 0;
  }

.popup-with-chats
  .popup-with-chats__soc-button {
    border-color: #E14313;
    border-radius: 0;
    background-color: transparent;
    color: #000;
  }
.popup-with-chats
  .popup-with-chats__soc-button  .popup-with-chats__icon path {
    fill: #E14313;
  }

.popup-with-chats .popup-with-chats__field-input .popup-with-chats__label {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.03em;

  color: #373737;
  background-color: transparent;
}
.popup-with-chats .popup-with-chats__field-input--with-content .popup-with-chats__label {
  display: none;
}

.button-with-chats {
  font-family: $font-family-rubik;
  &__label {
    background: #cd8d53;
  }
}

.ms-timer-block__title {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #FFFFFF;
  opacity: 0.7;
  padding-left: 0;
  text-align: left;
  letter-spacing: normal;
}

.ms-countdown__card {
  font-weight: 400;
  font-size: 74px;
  line-height: 0.9;
}
</style>
